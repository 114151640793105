@media (min-width: 768px) {
  .clients {
    display: flex;
    // gap: 2em;
    flex-wrap: wrap;
    margin-left: -1em;
    margin-right: -1em;
  }
  .portfolio__item {
    flex: 1 0 35%;
    margin-left: 1em;
    margin-right: 1em;
  }
}

@media (min-width: 840px) {
  body {
    overflow-x: hidden;
  }
  h1 {
    font-size: 4em;
  }

  .fadeIn {
    opacity: 0;
    transform: translateY(-20px);
  }

  .about h2 {
    font-size: 2em;
  }

  .partners h2,
  .contact h2 {
    font-size: 2em;
  }
  .nav a {
    margin-left: 2em;
  }

  .services {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 1em;
  }

  .card--services {
    padding: 1em;
    width: 100%;
  }

  .services--backdrop::before {
    clip-path: polygon(50% 0%, 0% 100%, 100% 46%);
    background: var(--clr-yellow);
    position: absolute;
    content: "";
    top: -2em;
    right: -2em;
    bottom: 0;
    left: 0;
    z-index: -100;
  }

  .about {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(8, 1fr);
  }

  .about__content {
    grid-column: 1 / 5;
    grid-row: 1 / -2;
    // background: var(--clr-blue);
    margin-left: 0em;
    margin-right: 0em;
    padding-top: 2em;

    padding: 2em;
    border-radius: var(--border-radius);
    // box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
  }

  .about .img1 {
    margin-top: 0;
  }

  .about__content__text {
    max-width: 70%;
    font-size: 1.2em;
  }

  .about__photo-container {
    grid-row: 2 / -1;
    grid-column: 4 / -1;
  }

  .card2--middle {
    margin-top: 2em;
  }

  .card2--right {
    margin-top: 4em;
  }

  .card2 {
    padding: 1em;
    margin-top: 1em;
  }

  // .contact::after {
  //   position: absolute;
  //   content: "";
  //   background: var(--clr-yellow);
  //   clip-path: polygon(-4.89% -1.37%, -4.84% 35.87%, 71.08% 44.26%);
  //   top: -10em;
  //   left: -4em;
  //   right: 0;
  //   bottom: 0;
  // }

  .partners__logos {
    display: flex;

    align-items: space-between;
  }
  .partners__logos img {
    border-radius: var(--border-radius);
    width: 90%;
    // box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
  }

  .swiper-container {
    display: none;
  }
}

@media (min-width: 1000px) {
  .card--services {
    padding: 2em;
  }
  .services {
    gap: 2em;
  }

  body::after {
    content: "";
    position: absolute;
    background: var(--clr-yellow);
    right: 0;
    top: 80px;
    clip-path: polygon(100% 30.8%, 2% 45px, 100% 72.6%);
    width: 500px;
    height: 500px;
    z-index: 10;
  }

  .introduction {
    max-width: 60%;
  }
}
