body {
  background: var(--clr-green);
  min-height: 100vh;
  overflow-x: hidden;
  position: relative;
}

section {
  margin-bottom: 2em;
}

#contact {
  margin-top: 2em;
}

.container {
  width: 90%;
  max-width: 1140px;
  margin: 0 auto;
}

.postcontainer {
  max-width: 600px;
  margin-bottom: 2em;
}

.landing {
  padding-top: 2em;
  padding-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3em;
}

.landing {
  display: block;
  padding-top: 0;
}

.landing__logo {
  width: 100%;
  max-width: 500px;
  padding: 1em;
  position: relative;
  z-index: 1;
}

.landing__logo::before {
  position: absolute;
  content: "";
  background: var(--clr-white);
  top: -10%;
  right: -20%;
  bottom: -100%;
  left: -2px;
  // border-bottom-right-radius: var(--border-radius);
  // border-top-right-radius: var(--border-radius);
  clip-path: polygon(56.67% 0px, 96% 44.25%, 2px 73.86%, 1px 7.64%);
  z-index: -10;
}

.landing__title {
  margin-bottom: 1em;
}

.logo__image {
  z-index: 3;
  position: relative;
}

.landing .card {
  max-width: 600px;
}

.about__photo-container {
  position: relative;
}

.about__content {
  background: var(--clr-blue);
  margin-left: -2.5em;
  margin-right: -2.5em;
  padding-top: 2em;
  padding-left: 2.5em;
  padding-right: 2.5em;
  padding-bottom: 2em;
}

.partners__logos {
  display: none;
}

.about .img1 {
  margin-top: 2em;
  border-radius: var(--border-radius);
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
  position: relative;
  display: block;
}

// clipping s
// .about .img2 {
//   position: absolute;
//   z-index: 3;
//   left: -30px;
//   top: 30px;
//   width: 60px;
// }
.contact {
  position: relative;
  margin-top: -3em;
  padding-top: 5em;
  margin-left: -4em;
  padding-left: 4em;
}

.contact--bg {
  position: relative;

  display: inline-block;
}

.contact--bg::after {
  position: absolute;
  content: "";
  background: var(--clr-white);
  z-index: -1;
  top: -7em;
  left: -4em;
  right: -7em;
  bottom: -2em;
  clip-path: polygon(58.61% 130px, 91.88% 101.53%, -55px 102.01%, -1px 16.74%);
}

.footer {
  background: var(--clr-blue);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
}

.footer p {
  margin-bottom: 0;
}

.clients__image {
  max-width: 200px;
  margin-bottom: 2em;
}

.post__image {
  max-width: 100%;
}

.clients__title {
  font-size: 2em;
  letter-spacing: 1px;
  color: white;
  margin-bottom: 0;
}
.clients__subtitle {
  margin-bottom: 2em;
}

.margin-bottom {
  margin-bottom: 2em;
}

.btn {
  padding: 0.5em 1em;
  background: var(--clr-yellow);
  color: var(--clr-blue);
  display: inline-block;
  border-radius: var(--border-radius);
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
  cursor: pointer;
  text-decoration: none;
}

.btn a {
  color: var(--clr-blue);
}

.btn:hover {
  background: var(--clr-blue);
  color: var(--clr-yellow);
}

.btn-action {
  margin-top: 2em;
  background: var(--clr-yellow);
  color: var(--clr-blue);
}

.btn-action:hover {
  background: var(--clr-blue);
  color: var(--clr-yellow);
}

.btn-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.portfolio,
.postcontainer {
  margin-top: 4em;
}
.postcontainer a {
  color: var(--clr-white);
  transition: color 0.3s ease-in-out;
}

.postcontainer a:hover {
  color: var(--clr-yellow);
}

.portfolio__item {
  background: rgba(255, 255, 255, 0.2);
  transition: background 0.3s ease-in-out;
  padding: 2em;
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.item-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.portfolio__item:hover {
  background: rgba(255, 255, 255, 0.3);
}

.video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  margin-bottom: 2em;
}

/* Then style the iframe to fit in the container div with full height and width */
.portfolio-video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.postimage {
  margin-bottom: 2em;
}

.fadeIn {
  opacity: 0;
  transform: translateY(0px);
}
.fadeInTriangle {
  opacity: 0;
}
