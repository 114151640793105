body {
  font-family: muli, sans-serif;
  font-size: 1.2rem;
  line-height: 1.6;
}

h1 {
  font-size: 2em;
  color: var(--clr-white);
  // text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
  z-index: 100;
  line-height: 1;
  margin-bottom: 0.5em;
}

.portfolio__title {
  color: var(--clr-blue);
}

h2 {
  line-height: 1.2;
  margin-bottom: 1em;
}

p,
h2 {
  color: var(--clr-blue);

  font-size: 1.2rem;
}

p {
  margin-bottom: 1em;
}

.about p {
  margin-bottom: 2em;
}

// h2 {
//   text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1);
// }

// .shadow {
//   text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1);
// }

.about p,
.about h2,
.footer p {
  color: var(--clr-white);
  opacity: 0.9;
}
