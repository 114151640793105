.card {
  background: var(--clr-white);
  background: linear-gradient(
    32deg,
    rgba(241, 240, 238, 0.2572071064754027) 10%,
    rgba(245, 241, 235, 0.9527813361672794) 90%
  );
  padding: 1em;
  border-radius: 10px;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.card--logo {
  background: linear-gradient(
    32deg,
    rgba(241, 240, 238, 0.7572071064754027) 10%,
    rgba(245, 241, 235, 0.9527813361672794) 90%
  );
}

.card__title {
  font-size: 2rem;
}

.card--services {
  margin-bottom: 2em;
}

.card2 {
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  background: var(--clr-green);
  align-self: start;
}
