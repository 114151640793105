nav {
  padding: 1em;
  margin-left: auto;
}

nav ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1em;
  list-style: none;
}

nav a {
  transition: color 0.2s ease-out;
}

nav a:hover {
  color: var(--clr-blue);
}

.nav__link {
  color: var(--clr-white);
  text-decoration: none;
  opacity: 0.9;
  font-size: 1rem;
}

.nav__link.current {
  text-decoration: underline;
}
