.swiper-container {
  width: 80%;
  height: 80%;
  border-radius: 10px;
}

.swiper-slide img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  object-position: center;
}

.swiper-button-prev,
.swiper-button-next {
  color: var(--clr-white);
}
